<template>
     <v-row class="ma-0 pa-0">

      <v-col class="ma-0 pa-0 py-1">
        <v-card  rounded max-width="360">
          <v-img
            :src= imagenGourmet
            height="150"
            width="360"
            class="white--text align-end titulo"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
          >
            <v-card-title class="title">RINCÓN GOURMET</v-card-title>
          </v-img>

          <v-bottom-navigation
            class=" text-center"
            grow
            :background-color=colorMenu
            dark
          >
            <v-btn v-for="(item,index) in items1" :key="index" :to="{ name: item.destino}">           
               {{item.texto1}}<br>
               {{item.texto2}}                
             <v-icon>{{ item.icono}}</v-icon>
           </v-btn>
           
          </v-bottom-navigation>
        </v-card>
      </v-col>

      <v-col class="ma-0 pa-0 py-1">
        <!--   Informacion Importante  -->
        <v-card rounded max-width="360">
          <v-img
            :src= imagenInformacion
           height="150"
            width="360"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
          >
            <v-card-title class="title">INFORMACIÓN IMPORTANTE</v-card-title>
          </v-img>
          <v-bottom-navigation
            class="text-center"
            grow
            :background-color=colorMenu
            dark
          >           
           <v-btn v-for="(item,index) in items2" :key="index" :to="{ name: item.destino}">           
               {{item.texto1}}<br>
               {{item.texto2}}                
             <v-icon>{{ item.icono}}</v-icon>
           </v-btn> 
          
       
          </v-bottom-navigation>
          <v-bottom-navigation
            class="text-center"
            grow
            
            :background-color=colorMenu
            dark            
          >           
            <v-btn v-for="(item,index) in items3" :key="index" :to="{ name: item.destino}">           
               {{item.texto1}}<br>
               {{item.texto2}}                
             <v-icon>{{ item.icono}}</v-icon>
           </v-btn>     
          </v-bottom-navigation>

          
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
</template>
<script>


export default {
  name:'complejoHome',
  props:{
         complejo: String,
         colorMenu: String,
         items1: Array,
         items2: Array,
         items3: Array,       

  },
  data() {
    return {     
      imagenInformacion:require('../assets/informacion' + this.complejo +'.jpg'),
      imagenGourmet:require('../assets/gourmet' + this.complejo +'.jpg'),
      miniVariant: false,
      title: "Rincon Club",
      activeBtn: null,
      activeBtn2: 0
    }
  },
  created: function() {
    //document.title = "Rincon de los Andes";
  }
}
</script>
<style scoped>

</style>
