<template>    
    <!--HEADER-->
    <v-card>
      <v-app-bar dense class="white elevation-1" fixed app>
        <img src="../assets/logorinconpng5.png" height="30" alt="" />
      </v-app-bar>
    </v-card>
    <!--HEADER FIN-->
</template>

<script>
export default {
      name: 'headerApp'
}
</script>